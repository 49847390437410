import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserApiService } from '@app/api/identity/user-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  // =========================================================================================================================================================
  // Ctor
  // =========================================================================================================================================================

  constructor(private userApiService: UserApiService) { }

  // =========================================================================================================================================================
  // Public Methods
  // =========================================================================================================================================================

  getCurrentUserAccount(): Observable<any> {
    return this.userApiService.getCurrentUserAccount();
  }
}

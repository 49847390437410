import * as moment from 'moment';

export class AuthenticationModel {

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.expiration = moment.utc(json.expiration).toDate();
            this.refreshTokenExpiration = moment.utc(json.refreshTokenExpiration).toDate();
        }
    }

    // =========================================================================================================================================================
    // Public Properties
    // =========================================================================================================================================================

    token: string;
    expiration: Date;
    refreshToken: string;
    refreshTokenExpiration;
}

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { MenuModule } from 'primeng/menu';
import { NgModule } from '@angular/core';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BsDropdownModule,
        PipesModule,
        TranslateModule,
        MenuModule,
    ],
    declarations: [
        HeaderComponent,
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule {}

<div #contentWrapper id="ic-cg-wrapper"
     [ngClass]="{'is-collapsibleX': isCollapsible, 'is-expandedX': isExpanded}"
     [ngStyle]="{'max-height': displayHeight, 'background-color': backgroundColor, 'cursor': cursor}"
>
    <ng-content></ng-content>

    <div *ngIf="isCollapsible" id="ic-cg-toggle" (click)="onToggleDiv()">
        <i class="fa-fw fa" [ngClass]="{'fa-minus': isExpanded, 'fa-plus': !isExpanded}"></i>
    </div>
</div>

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {OnOffSwitchModule} from './input/on-off-switch/on-off-switch.module';
import {Select2Module} from './input/select2/select2.module';

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: [
  ],
  exports: [
    Select2Module, OnOffSwitchModule
  ]

})
export class SmartadminFormsLiteModule {}

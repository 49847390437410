import { ToroEnums } from '@app/common/enumerations/toro.enums';
import CollapsibleDivState = ToroEnums.CollapsibleDivState;

export class CollapsibleDivItem {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.state) this.state = json.state as CollapsibleDivState;
        }
    }

    id: string;
    state = CollapsibleDivState.Open;
}

import { AccountsApiService } from '@app/api/accounts/accounts-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AccountsManagerService {

	// =========================================================================================================================================================
	// Ctor
	// =========================================================================================================================================================

	constructor(private accountsApiService: AccountsApiService) { }

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getAccountsForCurrentUser(): Observable<any> {
		return this.accountsApiService.getAccountsForCurrentUser();
	}
}

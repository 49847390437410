import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { IcentralError } from '@app/shared/models/api-interfaces.model';

export class BaseApiService {
    constructor(http: HttpClient, baseRoute: string) {
        this.apiRoute = baseRoute;
        this.httpClient = http;
    }

    private apiRoute: string;
    private httpClient: HttpClient;
    public PROXY_URL: string = "https://1aab9062-f933-4f8b-abec-3912a8c0d545.mock.pstmn.io";
    //public PROXY_URL: string = "https://88079d5b-ad4a-41a3-bf6b-d08781085175.mock.pstmn.io";

    protected readonly headers = {
        'Content-Type': 'application/json',
        'x-api-key': environment.apiKey
    };

    protected readonly defaultHttpOptions = {
        headers: new HttpHeaders(this.headers),
        observe: 'response' as 'response'
    };

    public getApiCall(endpoint: string, id = '', query = '') {

        const apiHost = this.apiRoute;
        let idCall = '';
        if (id) {
            idCall = '/' + id;
        }

        return apiHost + endpoint + idCall + query;
    }

    public getApiAppend(endpoint?: string, id?: string, query = '') {

        let apiCall = '';
        if (endpoint) {
            apiCall += endpoint;
        }
        if (id) {
            if (apiCall != '') {
                apiCall += '/';
            }
            apiCall += id;
        }
        return apiCall + query;
    }

    public joinApiCalls(...args: string[]): string {
        let api = args.shift();

        args.forEach(a => {
            api = api + '/' + a;
        });
        return api;
    }

    public handleError(error: HttpErrorResponse) {

        if (error.error instanceof IcentralError) {
            // A defined iCentralError with message.
            console.error('An error occurred:', error.error.detail);
            return throwError(error.error.detail);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        // the error contains the message but in some case i need to check the code
        // when it is thrown and handled inside the component, hence this change
        return throwError(error);
    }

    public get<T>(api: string, httpOptions?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        if (!httpOptions) {
            httpOptions = this.defaultHttpOptions;
        }
        return this.httpClient.get<T>(api, httpOptions).pipe(catchError(this.handleError));
    }

    public put<T>(api: string, body?: T, httpOptions?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<any>> {
        if (!httpOptions) {
            httpOptions = this.defaultHttpOptions;
        }
        return this.httpClient.put<T>(api, body, httpOptions).pipe(catchError(this.handleError));
    }

    public post<T>(api: string, body?: T, httpOptions?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<any>> {
        if (!httpOptions) {
            httpOptions = this.defaultHttpOptions;
        }
        return this.httpClient.post<T>(api, body, httpOptions).pipe(catchError(this.handleError));
    }

    public delete(api: string, httpOptions?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<any>> {
        if (!httpOptions) {
            httpOptions = this.defaultHttpOptions;
        }
        return this.httpClient.delete<any>(api, httpOptions).pipe(catchError(this.handleError));
    }

}

import CollapsibleDivState = ToroEnums.CollapsibleDivState;

import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { CollapsableDivComponent } from '@app/shared/collapsable-div/collapsable-div.component';
import { CollapsibleDivCollection } from '@app/common/services/models/collapsible-div-collection.model';
import { CollapsibleDivItem } from '@app/common/services/models/collapsible-div-item.model';
import { LocalCacheService } from '@app/common/services/local-cache.service';
import { ToroEnums } from '@app/common/enumerations/toro.enums';

@Component({
    selector: 'ic-collapsible-div-host',
    templateUrl: './collapsible-div-host.component.html',
    styleUrls: ['./collapsible-div-host.component.scss']
})
export class CollapsibleDivHostComponent implements OnInit, AfterContentInit {
    @ContentChildren(CollapsableDivComponent, { descendants: true }) childComponents: QueryList<CollapsableDivComponent>;

    private divStates: CollapsibleDivCollection;
    isInitialized = false;

    @Input() id: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private localCacheService: LocalCacheService) {}

    ngOnInit() {
        this.divStates = this.localCacheService.getCollapsibleDivCollection(this.id);
    }

    ngAfterContentInit() {
        setTimeout(() => {
            this.childComponents.forEach((child: CollapsableDivComponent) => {
                child.isExpandedChange.subscribe((event: { id: string, isExpanded: boolean }) => {
                    // Ignore this event while we are initializing the component.
                    if (!this.isInitialized) return;

                    this.saveDivStates(event);
                });

                // If we have a previous collapsibleDivState, set the current div to the appropriate state.
                if (this.divStates) {
                    const item = this.divStates.divs.find(d => d.id === child.id);
                    child.isExpanded = item == null ? true : item.state === CollapsibleDivState.Open;
                } else {
                    child.isExpanded = true;
                }
            });

            this.isInitialized = true;
        }, 1000);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private saveDivStates(event: { id: string, isExpanded: boolean }) {
        if (!this.divStates) {
            this.divStates = new CollapsibleDivCollection({ hostId: this.id });
        }

        const state = event.isExpanded ? CollapsibleDivState.Open : CollapsibleDivState.Closed;

        // Find existing item. If it exists, replace it. If not, add it.
        let item = this.divStates.divs.find(d => d.id === event.id);
        if (item) {
            item.state = state;
        } else {
            item = new CollapsibleDivItem({ id: event.id, state });
            this.divStates.divs.push(item);
        }

        this.localCacheService.collapsibleDivCollection = this.divStates;
    }

}

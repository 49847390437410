import { AuthHeaderModule } from './auth-header/auth-header.module';
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { EmptyLayoutComponent } from './app-layouts/empty-layout.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { HeaderModule } from './header/header.module';
import { MainLayoutComponent } from './app-layouts/main-layout/main-layout.component';
import { NavigationModule } from './navigation/navigation.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnerModule } from './spinner/spinner.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        AuthHeaderModule,
        HeaderModule,
        NavigationModule,
        FormsModule,
        RouterModule,

        TooltipModule,
        BsDropdownModule,
        SpinnerModule,
        TranslateModule
    ],
    declarations: [
        FooterComponent,
        MainLayoutComponent,
        EmptyLayoutComponent,
        AuthLayoutComponent,
    ],
    exports: [
        AuthHeaderModule,
        HeaderModule,
        NavigationModule,
        FooterComponent,
        SpinnerModule,
    ]
})
export class SmartadminLayoutModule {

}

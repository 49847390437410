import { Component, Input } from '@angular/core';

@Component({
  selector: 'ic-icon-svg',
  templateUrl: './icon-svg.component.html',
  styleUrls: ['./icon-svg.component.scss']
})
export class IconSvgComponent {
  @Input() iconName = '';

  constructor() { }
}

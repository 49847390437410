export class CcRequestInfo {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	accountId: string;
	controllerId: string;
	cloudId: string;
}

import { CollapsibleDivItem } from '@app/common/services/models/collapsible-div-item.model';

export class CollapsibleDivCollection {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.divs) this.divs = json.divs.map(d => new CollapsibleDivItem(d));
        }
    }

    hostId: string;
    divs: CollapsibleDivItem[] = [];
}

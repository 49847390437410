<div class="page-footer" id="footer">
    <div class="col col-xs-12 col-sm-6 legal">
        <div class="row">
            {{'TORO.FOOTER-COPYRIGHT' | translate}}
        </div>
        <div class="row">
            <ul class="link-group">
                <li>{{'TORO.DMCA-COPYRIGHT' | translate}}</li>
                <li><a href="http://www.rainmaster.com/terms-of-use.aspx">{{'SPECIAL.TERMS_OF_USE' | translate}}</a></li>
                <li>
                    <a href="http://www.rainmaster.com/privacy-policy.aspx">{{'SPECIAL.PRIVACY_POLICY' | translate}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="col hidden-xs col-sm-6">
        <div class="row">
            <div class="col col-xs-10 text-right">
                <b>{{'SPECIAL.CONNECT_WITH_US' | translate}}:</b>
            </div>
            <div class="col col-xs-2">
                <ul class="social">
                    <li><a (click)="onSocialClick(youtubeUrl)"><i class="fa fa-youtube"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

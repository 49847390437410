import { COMMTEST_RESULT, COMMTEST_STATUS, DEFAULT_GUID, SUBMIT_COMMAND } from './api-interfaces.model';
import { IMatDataRow } from '@app/shared/forms/shared-forms/mat-table/MatDataRow.model';
import { Permissions } from './icentral-constants.model';

export class AutoFlowStart {
    controllerID: string = DEFAULT_GUID;
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    percent: number = 0;
}

export class AutoFlowStop {
    readonly type: SUBMIT_COMMAND.AUTO_LEARN_FLOW_STOP;
    controllerID: string = DEFAULT_GUID;
}

export enum TransmissionDirection {
    None = 0,
    Receive = 1,
    Send = 2
}

export enum TransmissionStatus {
    None = 'None',
    SuccessfullyReceived = 'SuccessfullyReceived',
    SuccessfullyDelivered = 'SuccessfullyDelivered',
    InvalidRecipient = 'InvalidRecipient',
    ConnectionError = 'ConnectionError',
    ServerError = 'ServerError',
    InternalServiceUnavailable = 'InternalServiceUnavailable',
    WebException = 'WebException',
    OtherException = 'OtherException'
}

export enum TransmissionState {
    None = 'None',
    WaitingToBeSent = 'WaitingToBeSent',
    InProgress = 'InProgress',
    SendUnsuccessful = 'SendUnsuccessful',
    DeliverySuccessful = 'DeliverySuccessful',
    DeliveryRejected = 'DeliveryRejected',
    NeverDelivered = 'NeverDelivered',
    WillNeverBeSent = 'WillNeverBeSent',
    FiveMinutesLate = 'FiveMinutesLate',
    OneHourLate = 'OneHourLate',
    WaitingForResponse = 'WaitingForResponse',
    WillNeverBeReceived = 'WillNeverBeReceived',
    Cancelled = 'Cancelled'
}

export interface Transmission {
    id: string;
    controllerId: string;
    direction: TransmissionDirection;
    timeSent: Date;
    transmissionState: any;
    transmissionStatus: TransmissionStatus;
}

export interface CommandingResponse {
    id: string;
    status: COMMTEST_RESULT;
    createTime: Date;
    startTime: Date;
    completeTime: Date;
    command: any;
    user: string;
}

export interface AutoLearnStatus {
    estimatedStartTime: Date;
    estimatedEndTime: Date;
    runtimePerStationInSeconds: number;
    stations: AutoLearnStationStatus[];
    successful: boolean;
}

export interface AutoLearnStationStatus {
    number: number;
    timeRemainingInSeconds: number;
}

export interface AutoLearn {
    percent: number;
    scheduledRuntime: Date;
}

export interface AccountController extends IMatDataRow {
    accountID: string;
    accountName: string;
    id: string;
    name: string;
    number: number;
    location: string;
    controllerType: string;
    controllerTypeId: number;
    loading: boolean;
    legacyControllerID: number;
    permissions: Permissions;
    isOutOfSync: boolean;
}

export interface RetrieveFlowAccountController extends AccountController {
    flowEnabled: boolean;
    flowMeterStatuses: FlowMeterStatus[];
}

export interface FlowMeterStatus {
    flowMeterNumber: number;
    value1: FlowMeterRecord;
    value2: FlowMeterRecord;
}

export interface FlowMeterRecord {
    whenRetrieved: Date;
    gallons: number;
    ccf: number;
}



export interface AutoLearnAccountController extends AccountController {
    haveAlarms: boolean;
    isOutOfSync: boolean;
    inRainShutdown: boolean;
}

export interface AutoLearnStation extends IMatDataRow {
    stationNumber: number;
    stationName: string;
    stationGPM: number;
    isValidStation: boolean;
    done?: boolean;
    active?: boolean;
    endTime?: Date;
}

export interface RainShutdownAccountController {
    accountID: string;
    accountName: string;
    id: string;
    cloudID: string;
    name: string;
    number: number;
    location: string;
    controllerType: string;
    rainSourceID: number;
    rainSource: string;
    inRainMode: boolean;
    remainingShutdownDays: string;
    status: COMMTEST_STATUS;
    history: boolean;
    submitID: string;
    selected: boolean;
    loading: boolean;
    disabled: boolean;
    permissions: Permissions;
}

import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@app/core/guards/auth-guard.service';
import { AuthLayoutComponent } from './shared/layout/app-layouts/auth-layout.component';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout/main-layout.component';
import { NgModule } from '@angular/core';

const children: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
        data: { pageTitle: 'Home' }
    },
    {
        path: 'account',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
        data: { pageTitle: 'Account' }
    },
    {
        path: 'user',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/user/user.module').then(m => m.UserModule),
        data: { pageTitle: 'User Permissions' }
    },
    {
        path: 'controller',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/controller/controller.module').then(m => m.ControllerModule),
        data: { pageTitle: 'Controller' }

    },
    {
        path: 'manual-ops',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/manual-ops/manual-ops.module').then(m => m.ManualOpsModule),
        data: { pageTitle: 'Manual Ops' }
    },
    {
        path: 'shutdown',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/shutdown/shutdown.module').then(m => m.ShutdownModule),
        data: { pageTitle: 'Shutdown' }

    },
    {
        path: 'weather',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/weather/weather.module').then(m => m.WeatherModule),
        data: { pageTitle: 'Weather' }

    },
    {
        path: 'report',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule),
        data: { pageTitle: 'Report' }
    },
    {
        path: 'program',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./features/program/program.module').then(m => m.ProgramModule),
        data: { pageTitle: 'Program' }
    },
    {
        path: '',
        loadChildren: () => import('./features/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule),
        data: { pageTitle: 'Miscellaneous' }
    }

];

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        component: MainLayoutComponent,
        data: { pageTitle: 'Home' },
        children: children
    },
    {
        path: 'pda',
        canActivate: [AuthGuardService],
        component: MainLayoutComponent,
        data: { pda: true },
        children: children
    },

    {
        path: '',
        component: AuthLayoutComponent,
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    },

    { path: '**', redirectTo: 'ah-snap' }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}

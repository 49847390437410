import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthManagerService {

    private _isLoggedIn = false;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    set isLoggedIn(value: boolean) {
        this._isLoggedIn = value;
    }

    get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }
}

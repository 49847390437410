import { HttpErrorHandler, MessageService, services } from '@app/core/services';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataStorage } from './services/data-storage.service';
import { IonicStorageModule } from '@ionic/storage';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';

@NgModule({
    imports: [
        CommonModule,
        IonicStorageModule.forRoot(),
    ],
    exports: [],
    providers: [
        ...services,
        HttpErrorHandler,
        MessageService,
        DataStorage,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

}

import { AuthHeaderComponent } from './auth-header.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AuthHeaderComponent,
  ],
  exports: [
    AuthHeaderComponent
  ]
})
export class AuthHeaderModule {}

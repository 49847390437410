export class CcControllerActionStatus {
	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	cloudID: string;
	controllerStatus: string;
	failReason: string;
	responseTime: number;
}

import { CollapsibleDivCollection } from '@app/common/services/models/collapsible-div-collection.model';
import { Injectable } from '@angular/core';

enum cacheKey {
    CollapsibleDivCol = 'collapsibleDivCol',
    iCentralAccessToken = 'iCentralAccessToken',
    iCentralRefreshToken = 'iCentralRefreshToken',
}

@Injectable({
    providedIn: 'root'
})
export class LocalCacheService {

    constructor() {
    }

    set collapsibleDivCollection(value: CollapsibleDivCollection) {
        localStorage[`${cacheKey.CollapsibleDivCol}=${value.hostId}`] = JSON.stringify(value);
    }

    getCollapsibleDivCollection(hostId: string): CollapsibleDivCollection {
        const cachedDivCollection = localStorage[`${cacheKey.CollapsibleDivCol}=${hostId}`];
        return cachedDivCollection ? new CollapsibleDivCollection(JSON.parse(cachedDivCollection)) : null;
    }

    set iCentralApiAccessToken(value: string) {
        localStorage[cacheKey.iCentralAccessToken] = value;
    }

    get iCentralApiAccessToken(): string {
        return localStorage[cacheKey.iCentralAccessToken] || '';
    }

    set iCentralApiRefreshToken(value: string) {
        localStorage[cacheKey.iCentralRefreshToken] = value;
    }

    get iCentralApiRefreshToken(): string {
        return localStorage[cacheKey.iCentralRefreshToken] || '';
    }
}

import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'ic-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    // searchMobileActive = false;
    items: MenuItem[];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private router: Router,
                private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.items = [
            {
                label: this.translateService.instant('MENUS.SIGN_OUT'),
                icon: 'fa fa-sign-out',
                command: this.onLogoutClick.bind(this)
            }
        ];
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onLogoutClick() {
        this.router.navigate(['/logout']);
    }
}

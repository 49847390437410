<div class="ic-big-breadcrumbs">
    <h1 class="page-title">
        <a *ngIf="linksEnabled && items.length > 1" (click)="onTitleClick()">
            <i class="fa-fw fa fa-{{icon}}"></i>
            {{items[0]}}
        </a>
        <span class="h1-font" *ngIf="!linksEnabled || items.length <= 1">
            <i class="fa-fw fa fa-{{icon}}"></i>{{items[0]}}
        </span>
        <span *ngFor="let item of items.slice(1); let i = index" class="subItems">
            <i class="fa fa-angle-right separator" aria-hidden="true"></i>
            <a *ngIf="linksEnabled && i < items.length - 2" (click)="onItemClick(i)">
                {{item}}
            </a>
            <span>{{!linksEnabled || i == items.length - 2 ? item : "" }}</span>
        </span>
        <i class="fa-fw fa fa-{{postIcon}}" *ngIf="postIcon && items.length > 1" (click)="onIconClick()"></i>
    </h1>
</div>
<bryntum-calendar #calendar 
    [events]="events!" 
    [eventEditFeature]="features.eventEdit!" 
    [dragFeature]="features.drag!"
    [eventMenuFeature]="features.eventMenu!" 
    [eventTooltipFeature]="features.eventTooltip!"
    [printFeature]="features.print!" 
    [scheduleMenuFeature]="features.scheduleMenuFeature!" 
    [resources]="resources!"
    [date]="date!" 
    [maxDate]="maxDate" 
    [modes]="modes!" 
    [mode]="mode!" 
    [tbar]="tbar!" 
    [sidebar]="sidebar!"
    [listeners]="listener" 
    [responsive]="responsive">
</bryntum-calendar>
import { IEnvironment } from '@env/ienvironment';

export const environment: IEnvironment = {
  production: false,
  environmentName: 'Development',

  firebase: {
  },

  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },

  identityApiUrl: 'https://api.nt-dev.icentral.io/identity',
  accountApiUrl: 'https://api.nt-dev.icentral.io/account-mgmt',
  commandingApiUrl: 'https://api.nt-dev.icentral.io/commanding',
  legacyBaseUrl: 'https://www-dev.irrigdev.net/iCentral',
  apiKey: 'U9ksTUqNtm5FIITGgITTb9ego9OY7Keg5sc0KHus',

  youtubeUrl: 'https://www.youtube.com/user/RainMasterCS',

  awsAppSyncUrl: 'https://appsync.nt-dev.icentral.io/graphql',
  awsAppSyncRegion: 'us-west-2',
  awsAppSyncApiKey: 'da2-7rckmsk7gzhexgdq4dh2mrlq7i'
};

import { ApiService } from './api.service';
import { BodyService } from './body.service';
import { ChatService } from './chat.service';
import { CommandingService } from './commanding.service';
import { ConnectionService } from './connection.service';
import { ErrorHandlerService } from './error-handler.service';
import { HttpErrorHandler } from './http-error-handler.service';
import { IdentityApiService } from './identity.service';
import { LoggerService } from './logger.service';
import { MessageService } from './message.service';
import { NotificationService } from './notification.service';
import { SoundService } from './sound.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

export const services = [
  ErrorHandlerService,
  StorageService,

  ApiService,
  ConnectionService,
  CommandingService,
  IdentityApiService,
  UserService,
  ChatService,
  ErrorHandlerService,
  NotificationService,
  BodyService,
  SoundService,

  HttpErrorHandler,
  LoggerService,
  MessageService
];

export * from './storage.service';
export * from './api.service';
export * from './connection.service';
export * from './commanding.service';
export * from './user.service';
export * from './chat.service';
export * from './error-handler.service';
export * from './notification.service';
export * from './body.service';
export * from './sound.service';

export * from './http-error-handler.service';
export * from './logger.service';
export * from './message.service';

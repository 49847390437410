import { CcrControllerStatus } from '@app/common/services/models/cloud-connect/ccr-controller-status.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BroadcastService {

	// =========================================================================================================================================================
	// General Events
	// =========================================================================================================================================================

	userLoggedOut = new Subject<void>();

	// =========================================================================================================================================================
	// Cloud Connect Events
	// =========================================================================================================================================================

	controllerStatusChange = new Subject<CcrControllerStatus>();

}

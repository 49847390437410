import { ApiService, HttpMethod } from '@app/api/_common/api.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserApiService extends ApiService {

	// =========================================================================================================================================================
	// Ctor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected http: HttpClient) {
		super(http);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getCurrentUserAccount(): Observable<any> {
		return this.apiRequest<any>(this.getUserAccountUrl, HttpMethod.Get);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================

	public get baseApiUrl(): string {
		return `${environment.identityApiUrl}/api/`;
	}

	private get getUserAccountUrl(): string { return`${this.baseApiUrl}account`; }
}

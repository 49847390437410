import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ChatService {

    url: string;

    public messageToSubject;
    public newMessage;

    constructor() {
        this.url = '/chat/chat.json';
        this.messageToSubject = new Subject();
        this.newMessage = new Subject();
    }

    getChatState() {
        return undefined; // this.jsonApiService.fetch(this.url)

    }

    messageTo(user) {
        this.messageToSubject.next(user);
    }

    sendMessage(message) {
        this.newMessage.next(message);

    }

}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum HttpMethod {
    Get,
    Post,
    Put,
    Patch,
    Delete
}

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService {

    public abstract get baseApiUrl(): string;

    protected constructor(protected http: HttpClient) { }

    private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');

    apiRequest<T>(url: string, httpMethod: HttpMethod, httpBody?: any): Observable<T> {
        if (url.length > 2000) {
            const urlWithoutParams = url.split('?');
            throw new URIError(`apiRequest URI (${urlWithoutParams[0]}) is too long. This may indicate an API error. Consult the factory.`);
        }

        return this.http.request<T>(HttpMethod[httpMethod], url, { headers: this.headers, body: httpBody });
    }

    patchTransform(object: object) {
        return Object.keys(object).map(key => ({
            op: 'replace',
            path: `/${key}`,
            value: object[key]
        }));
    }
}

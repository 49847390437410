import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'on-off-switch',
  templateUrl: './on-off-switch.component.html',
})
export class OnOffSwitchComponent implements OnInit {

  constructor() {
  }


  static widgetsCounter = 0;

  @Input() title: string;

  @Input() model: boolean;
  @Output() modelChange = new EventEmitter();

  @Input() value: any;

  public widgetId;


  ngOnInit() {
    this.value = this.model;

    this.widgetId = 'on-off-switch' + OnOffSwitchComponent.widgetsCounter++;
  }

  onChange() {
    this.modelChange.emit(this.value);
  }
}

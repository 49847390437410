import { Injectable } from '@angular/core';
import { ToroEnums } from '@app/common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import LanguageCode = ToroEnums.LanguageCode;
import { forkJoin } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {

    constructor(private translateService: TranslateService,
    ) {
    }

    load() {
        return new Promise((resolve, reject) => {
            this.translateService.addLangs([
                LanguageCode.English_US
                // , LanguageCode.Spanish, LanguageCode.French, LanguageCode.German, LanguageCode.Italian, LanguageCode.Portuguese,
                // LanguageCode.Dutch, LanguageCode.Swedish, LanguageCode.Japanese, LanguageCode.Korean, LanguageCode.Chinese_Simplified,
                // LanguageCode.Vietnamese, LanguageCode.Russian, LanguageCode.Bulgarian, LanguageCode.Danish, LanguageCode.Norwegian, LanguageCode.Czech
            ]);
            this.translateService.setDefaultLang(LanguageCode.English_US);

            // We need to wait for the strings to be loaded, too. Since we don't yet know which of the supported languages
            // will be used, we load them all.
            const todo = this.translateService.langs.map((lang) => this.translateService.getTranslation(lang));

            // Do all the retrievals and resolve our promise when complete.
            forkJoin(todo)
                .subscribe(() => {
                    resolve(true);
                });
        });
    }

}

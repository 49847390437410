import { Component } from '@angular/core';

// declare var $: any;

@Component({
    selector: 'app-auth-header',
    templateUrl: './auth-header.component.html',
})
export class AuthHeaderComponent {
}

import { CapitalizePipe } from '@app/shared/pipes/capitalize.pipe';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FieldFilterPipe, MomentPipe, CapitalizePipe],
  exports: [FieldFilterPipe, MomentPipe, CapitalizePipe]
})
export class PipesModule { }

<div *ngIf="!isPda" id="ic-main-container">
    <div id="ic-main-header">
        <ic-header></ic-header>
        <ic-menu-bar></ic-menu-bar>
    </div>

    <div id="ic-main-content-wrapper">
        <div id="ic-main-content">
            <router-outlet #o="outlet"></router-outlet>
        </div>
    </div>

    <div id="ic-main-footer" style="flex: 1; max-height: 62px;">
        <ic-footer></ic-footer>
    </div>
</div>

<div *ngIf="isPda">
    <router-outlet #o="outlet"></router-outlet>
</div>

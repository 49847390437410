import { BootstrapModule } from '@app/shared/bootstrap.module';
import { BryntumCalendarModule } from '@bryntum/calendar-angular';
import { CalendarComponent } from './calendar/calendar.component';
import { CollapsableDivComponent } from '@app/shared/collapsable-div/collapsable-div.component';
import { CollapsibleDivHostComponent } from '@app/shared/collapsible-div-host/collapsible-div-host.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { IconSvgComponent } from './icon-svg/icon-svg.component';
import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { ScreenFitContentComponent } from './screen-fit-content/screen-fit-content.component';
import { SmartadminFormsLiteModule } from './forms/smartadmin-forms-lite.module';
import { SmartadminLayoutModule } from './layout';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BootstrapModule,
        BryntumCalendarModule,
        HighchartsChartModule,
        SmartadminLayoutModule
    ],
    declarations: [
        CalendarComponent,
        CollapsableDivComponent,
        CollapsibleDivHostComponent,
        IconSvgComponent,
        ScreenFitContentComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BootstrapModule,
        BryntumCalendarModule,
        HighchartsChartModule,
        PipesModule,
        SmartadminLayoutModule,
        SmartadminFormsLiteModule,

        CalendarComponent,
        CollapsableDivComponent,
        CollapsibleDivHostComponent,
        IconSvgComponent,
        ScreenFitContentComponent
    ]
})
export class SharedModule { }

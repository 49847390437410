import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

const defaultUser = {
  username: 'Guest'
};
@Injectable()
export class UserService {
  public user$ = new BehaviorSubject({...defaultUser});

  constructor() {
  }

  public logout() {
    this.user$.next({...defaultUser});
  }

}

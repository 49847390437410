import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ic-big-breadcrumbs',
    templateUrl: './big-breadcrumbs.component.html',
    styleUrls: ['./big-breadcrumbs.component.scss']
})
export class BigBreadcrumbsComponent implements OnInit {
    @Input() public icon: string;
    @Input() public postIcon: string;
    @Input() public items: Array<string>;
    @Input() public linksEnabled: boolean;

    @Output() titleClicked = new EventEmitter();
    @Output() itemClicked = new EventEmitter();
    @Output() iconClicked = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onItemClick(index: number) {
        this.itemClicked.emit(index + 1);
    }

    onIconClick() {
        this.iconClicked.emit();
    }

    onTitleClick() {
        this.titleClicked.emit();
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routerTransition } from '@app/shared/utils/animations';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    animations: [routerTransition]
})
export class MainLayoutComponent implements OnInit {
    isPda = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        if (this.route.data) {
            this.route.data.subscribe(next => this.isPda = next['pda']);
        }
    }
}

import { DataStorage } from './data-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IcentralError } from '@app/shared/models/api-interfaces.model';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';

@Injectable()
export class ErrorHandlerService {

    constructor(private notificationService: NotificationService, private dataStore: DataStorage) { }

    handleError(error: HttpErrorResponse) {
        let title = 'Error';
        let content = 'Something went wrong - try again or refresh the page.';

        if (error.status == 0) {
            content = 'Unable to establish a network connection.';
        } else if (error.status == 401) {
            return;
        } else {
            let ierror = this.dataStore.getLastError();
            if (!ierror) {
                ierror = error.error as IcentralError;
            }
            if (ierror) {
                title = ierror.title ? ierror.title : title;
                content = ierror.detail ? ierror.detail : content;
            }
        }
        this.notificationService.bigBox(
            {
                title: title,
                content: content,
                color: '#C46A69',
                icon: 'fa fa-warning shake animated',
                timeout: 4000
            }
        );
    }

    displayWarningMessage(message: string) {
        this.notificationService.bigBox(
            {
              title: 'Warning',
              content: message,
              color: '#C79121',
              icon: 'fa fa-warning shake animated',
              timeout: 4000
            });
    }

    displaySuccessMessage(message: string) {
        this.notificationService.bigBox(
            {
              title: 'Success',
              content: message,
              color: '#739E73',
              icon: 'fa fa-check',
              timeout: 3000
            });
    }

  displayInfoMessage(message: string) {
    this.notificationService.bigBox(
      {
        title: 'Info',
        content: message,
        color: '#305d8c',
        icon: 'fa fa-info shake animated',
        timeout: 5000
      });
  }

  displayErrorMessage(message: string) {
    this.notificationService.bigBox(
      {
        title: 'Error',
        content: message,
        color: '#C46A69',
        icon: 'fa fa-warning shake animated',
        timeout: 4000
      });
  }
}

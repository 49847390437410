import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment.qa';

@Component({
    selector: 'ic-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    youtubeUrl: string;

    constructor() { }

    ngOnInit() {
        this.youtubeUrl = environment.youtubeUrl;
    }

    onSocialClick(url: string) {
        window.open(url, 'socialTab').focus();
    }

}

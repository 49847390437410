import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {

  constructor() { }

  log(msg: any) { console.log(msg); }

  fatal(msg: any) { console.log(msg); }
  error(msg: any) { console.log(msg); }
  warn(msg: any) { console.log(msg); }
  info(msg: any) { console.log(msg); }
  debug(msg: any) { console.log(msg); }
  trace(msg: any) { console.log(msg); }
}

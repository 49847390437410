import { AfterViewInit, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { BryntumCalendarComponent } from '@bryntum/calendar-angular';
import { Calendar } from '@bryntum/calendar';
import { Router } from '@angular/router';

@Component({
  selector: 'ic-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CalendarComponent implements AfterViewInit {

  calendar!: Calendar;

  @ViewChild(BryntumCalendarComponent) calendarComponent!: BryntumCalendarComponent;

  @Input() events: Array<Record<string, any>>;
  @Input() resources: Array<Record<string, any>>;
  @Input() date: Date;
  @Input() maxDate: Date;
  @Input() modes: any;
  @Input() mode: string;
  @Input() tbar: any;
  @Input() features: any;
  @Input() controllerId: string;
  @Input() sidebar: any;
  @Input() datepicker: any;
  @Input() listener: any;
  @Input() responsive: any;


  private readonly REPORT_ROUTE = "/report/irrigation";
  private readonly CHILD_ROUTE = "program";

  constructor(private route: Router) { }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.instance;
  }

  onEventClick(e: { [key: string]: any }) {
    if (this.route.url.startsWith(this.REPORT_ROUTE)) {
      let resourceId = e.eventRecord.data.resourceId;
      let programName = this.resources.filter(r => r.id == resourceId)[0].name;

      this.route.navigate([
        `${this.REPORT_ROUTE}/${this.CHILD_ROUTE}`,
        `${resourceId}`,
        `${programName}`
      ], {
        queryParams: {
          'id': this.controllerId
        },
        queryParamsHandling: 'merge'
      });
    }
  }
}

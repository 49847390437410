<header id="ic-header">
    <div id="ic-logo-group">
        <span id="ic-logo"> <img src="assets/img/icentral_logo_white.png"> </span>
    </div>

    <div id="ic-profile-img">
        <img src="assets/img/avatars/sunny.png" alt="John Doe" (click)="menu.toggle($event)"/>
        <p-menu #menu [model]="items" popup="true"></p-menu>
    </div>

</header>

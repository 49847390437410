import { ApiService, HttpMethod } from '@app/api/_common/api.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AccountsApiService extends ApiService {

	// =========================================================================================================================================================
	// Ctor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(protected http: HttpClient) {
		super(http);
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	getAccountsForCurrentUser(): Observable<any> {
		return this.apiRequest<any>(this.accountsForUserUrl, HttpMethod.Get);
	}

	// =========================================================================================================================================================
	// URLs
	// =========================================================================================================================================================

	public get baseApiUrl(): string {
		return `${environment.accountApiUrl}/api/`;
	}

	private get accountsForUserUrl(): string { return `${this.baseApiUrl}accounts`; }
}

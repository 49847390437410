<section id="nt-top-wrapper">
    <div class="screen-fit-breadcrumbs">
        <article class="container-fluid">
            <div class="row" *ngIf="bbItems">
                <ic-big-breadcrumbs class="col-xs-12 col-sm-7 col-md-7 col-lg-4" [items]="bbItems" [icon]="bbIcon"
                    (titleClicked)="bbTitleClicked()" (itemClicked)="bbItemClicked($event)">
                </ic-big-breadcrumbs>
            </div>
        </article>
    </div>
    <article class="nt-well nt-flex-fill-height">
        <div class="container-fluid nt-fill-height">
            <div class="row nt-fill-height">
                <div class="col-sm-12 nt-fill-height">
                    <app-spinner [show]="loading" class="screen-fit-flex-spinner nt-flex-col nt-fill-height">
                        <div *ngIf="!loading" class="nt-flex-col nt-fill-height">
                            <ng-content></ng-content>
                        </div>
                    </app-spinner>
                </div>
            </div>
        </div>
    </article>
</section>
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private connectionSub = new BehaviorSubject<string>(null);
  connectionFlag = this.connectionSub.asObservable();

  constructor() {
  }

  sendFlag(status: string) {
    this.connectionSub.next(status);
  }
}

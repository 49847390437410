import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'ic-collapsable-div',
    templateUrl: './collapsable-div.component.html',
    styleUrls: ['./collapsable-div.component.scss']
})
export class CollapsableDivComponent implements AfterViewInit, DoCheck {
    @ViewChild('contentWrapper') contentWrapper: ElementRef;

    private readonly HEADER_HEIGHT = 34;    // NOTE: This value must match the value set for @ic-cg-header-height in our .scss file.
    private readonly HEADER_BOTTOM_PAD = 1;
    private readonly ADJUSTED_HEADER_HEIGHT = this.HEADER_HEIGHT + this.HEADER_BOTTOM_PAD;

    @Output() isExpandedChange = new EventEmitter<{ id: string, isExpanded: boolean }>();

    @Input() id: string;
    @Input() isCollapsible = false;
    @Input() backgroundColor = 'white';
    @Input() cursor = 'default';

    private _isExpanded = false;
    @Input() set isExpanded(value: boolean) {
        this._isExpanded = value;
        this.isExpandedChange.next({ id: this.id, isExpanded: value });
        this.setDisplayHeight();
    }

    get isExpanded(): boolean {
        return this._isExpanded;
    }

    contentHeight = 0;
    displayHeight = this.isExpanded || !this.isCollapsible ? 'auto' : `${this.ADJUSTED_HEADER_HEIGHT}px`;
    widgetBody: any;

    // =========================================================================================================================================================
    // Lifecycle Hooks
    // =========================================================================================================================================================

    ngAfterViewInit() {
        setTimeout(() => {
            // Set the height of the contentWrapper to an exact height to support sizing animation.
            this.widgetBody = this.contentWrapper.nativeElement.querySelector('.widget-body');
            if (this.widgetBody) {
                this.contentHeight = this.widgetBody.clientHeight;
                this.setDisplayHeight();
            }
        }, 500);
    }

    ngDoCheck() {
        if (this.widgetBody && this.widgetBody.clientHeight != this.contentHeight) {
            this.contentHeight = this.widgetBody.clientHeight;
            this.setDisplayHeight();
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onToggleDiv() {
        this.isExpanded = !this.isExpanded;
        this.setDisplayHeight();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayHeight() {
        this.displayHeight
            = this.isExpanded || !this.isCollapsible ? `${this.contentHeight + this.ADJUSTED_HEADER_HEIGHT}px` : `${this.ADJUSTED_HEADER_HEIGHT}px`;
    }
}

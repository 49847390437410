import { Component, Input, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'ic-screen-fit-content',
  templateUrl: './screen-fit-content.component.html',
  styleUrls: ['./screen-fit-content.component.scss']
})
export class ScreenFitContentComponent implements OnDestroy {
  @Input() bbItems: string[] = [];
  @Input() bbIcon: string = '';
  @Input() bbItemsLink: string[] = [];
  @Input() loading: boolean = true;

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'screen-fit-layout');
  }

  bbTitleClicked() {
    //set if link clickable
  }

  bbItemClicked($event) {
    //If link clickable
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'screen-fit-layout');
  }
}

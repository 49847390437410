import { IcentralError } from '@app/shared/models/api-interfaces.model';
import { Injectable } from '@angular/core';

@Injectable()
export class DataStorage {
  private data: any;
  private error: IcentralError;

  public constructor() {}

  public storeData(d: any) {
    this.data = d;
  }

  public getData(): any {
    return this.data;
  }

  public storeError(e: IcentralError) {
    this.error = e;
  }

  public getLastError() {
    return this.error;
  }
}

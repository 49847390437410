export class TokenLoginModel {

    constructor(refreshToken: string, grantType?: string) {
        if (grantType != null) this.grantType = grantType;
        this.refreshToken = refreshToken;
    }

    grantType = 'refresh_token';
    refreshToken: string;

}

import { ControllerPermissions, FlagsHelper, Permissions } from './icentral-constants.model';

export const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';

export interface IEmail {
    email: string;
}

export interface ILogin {
    email: string;
    password: string;
}

export interface IRegistration {
    email: string;
    password: string;
    confirmEmail: boolean;
    firstName: string;
    lastName: string;
}

export interface IConfirmEmailData {
    userId: string;
    code: string;
    password: string;
}

export interface IResetPassword {
    userId: string;
    code: string;
    password: string;
}

export interface IInviteNewUser {
    accountName: string;
    accountId: string;
    email: string;
}

export interface IInviteCallback {
    token: string;
    userId: string;
    accountId: string;
}

export class IcentralError {
    code: number;
    log_level: string;
    product: string;
    type: string;
    title: string;
    status: number;
    detail: string;
    instance: string;
    errors: { [index: string]: string[] };
}

export class ICentralUserInfo {
    id = '';
    firstName = '';
    lastName = '';
    email = '';
    userName = '';
    role = '';
    roleId = 0;
    lastLoginDate: Date = new Date(Date.now());

    isValidUser(): boolean {
        return this.roleId > 0;
    }

    isToroUser(): boolean {
        return this.roleId <= 6 && this.isValidUser();
    }

    isToroTechSupport(): boolean {
        return this.roleId == 2 && this.isValidUser();
    }

    canEditControllerBilling(): boolean {
        return ([1, 2, 3, 6]).includes(this.roleId) && this.isValidUser();
    }

    isEagleSpecialEditable(): boolean {
        // TODO: Get rid of business logic in client.
        return ([1, 2, 4, 6].includes(this.roleId)) && this.isValidUser();
    }

    isUser(): boolean {
        return this.roleId == 8 && this.isValidUser();
    }

    canApplyCredit(): boolean {
        return (this.roleId == 3 || this.roleId == 1) && this.isValidUser();
    }
}


export interface IContact {
    id: number;
    firstName: string;
    lastName: string;
    title: string;
    companyName: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    stateID: number;
    zip: string;
    countryID: number;
    email: string;
    preferredPhone: string;
    workPhone: string;
    mobilePhone: string;
    notes: string;

    printPreferredPhone(): string;
}

export interface IAccount {
    accountID: string;
    legacyAccountID: number;
    accountTerritoryID: number;
    organizationNumber: number;
    legacyOrganizationID: number;
    roleID: string;
    paymentLockout: boolean;
    accountName: string;
    location: string;
    timeZoneID: number;
    daylightSavingsEnabled: boolean;
    accountStatusID: number;
    accountComments: string;
    dateCreation: Date;
    parentAccountID: string;
    zip: string;
    mainContact: IContact;
    billingContact: IContact;
    hierarchyLevel: number;
    subAccounts: IAccount[];
    permissions: Permissions;
    balance: number;
    oldestDaysPastDue: number;
    sameContact: boolean;
}

export interface IUser {
    userID: string;
    legacyUserID: number;
    roleID: string;
    accessFailedCount: number;
    firstName: string;
    lastName: string;
    email: string;
    emailConfirmed: boolean;
    lockoutEnabled: boolean;
    lockoutEnd: Date;
    normalizedEmail: string;
    userName: string;
    normalizedUserName: string;
    twoFactorEnabled: boolean;
    configID: number;
    dateLastStatusChange: Date;
    reasonLastStatusChange: string;
    pendingLastLoginIP: string;
    pendingLastLogin: Date;
    lastLoginIP: string;
    lastLoginDate: Date;
    preferredPhone: string;
    preferredPhoneConfirmed: boolean;
    workPhone: string;
    mobilePhone: string;
    dateCreation: Date;
    comments: string;
    statusID: number;
    permissions: Permissions;
    role: string;
    editUserPermissions: boolean;
    accessLevel: string;

    isAdmin(): boolean;
}

export class Contact implements IContact {
    id: number;
    firstName = '';
    lastName = '';
    title = '';
    companyName = '';
    streetAddress1 = '';
    streetAddress2: string;
    city = '';
    stateID = 0;
    zip = '';
    countryID = 0;
    email = '';
    preferredPhone = '';
    workPhone = '';
    mobilePhone = '';
    notes: string;

    printPreferredPhone(): string {
        switch (this.preferredPhone) {
            case 'm':
                return this.mobilePhone;
            case 'w':
                return this.workPhone;
            default:
                return '';
        }
    }
}

export class Account implements IAccount {
    static readonly MAX_HIERARCHY_LEVEL = 3;

    accountID: string;
    legacyAccountID = 0;
    accountTerritoryID: number;
    roleID: string;
    organizationNumber: number;
    legacyOrganizationID: number;
    paymentLockout: boolean;
    dateCreation: Date = new Date(Date.now());
    hierarchyLevel: number;
    accountName = '';
    location = '';
    timeZoneID = 0;
    daylightSavingsEnabled: boolean;
    accountStatusID: number;
    accountComments = '';
    parentAccountID = '';
    zip = '';
    mainContact: IContact;
    billingContact: IContact;
    subAccounts: IAccount[];
    permissions: Permissions;
    balance = 0.0;
    oldestDaysPastDue = 0;
    sameContact = true;


    private static addAccountAndAllSubAccounts(a: IAccount, allAccounts: IAccount[]): IAccount[] {
        allAccounts.push(a);
        if (!a.subAccounts || a.subAccounts.length <= 0) {
            return allAccounts;
        }
        for (let i = 0; i < a.subAccounts.length; i++) {
            Account.addAccountAndAllSubAccounts(a.subAccounts[i], allAccounts);
        }
        return allAccounts;
    }

    static addAllAccounts(accounts: IAccount[]): IAccount[] {
        const allAccounts = new Array<IAccount>();
        for (let i = 0; i < accounts.length; i++) {
            Account.addAccountAndAllSubAccounts(accounts[i], allAccounts);
        }
        return allAccounts;
    }
    static isMainAccount(account: IAccount): boolean {
        return account.parentAccountID == DEFAULT_GUID || account.hierarchyLevel == 1;
    }
}

export class User implements IUser {
    userID: string;
    legacyUserID: number;
    roleID: string;
    accessFailedCount: number;
    firstName: string;
    lastName: string;
    email: string;
    emailConfirmed: boolean;
    lockoutEnabled: boolean;
    lockoutEnd: Date;
    normalizedEmail: string;
    userName: string;
    normalizedUserName: string;
    twoFactorEnabled: boolean;
    configID: number;
    dateLastStatusChange: Date;
    reasonLastStatusChange: string;
    pendingLastLoginIP: string;
    pendingLastLogin: Date;
    lastLoginIP: string;
    lastLoginDate: Date;
    preferredPhone: string;
    preferredPhoneConfirmed: boolean;
    workPhone: string;
    mobilePhone: string;
    dateCreation: Date;
    comments: string;
    statusID: number;
    permissions: Permissions;
    role: string;
    editUserPermissions: boolean;
    accessLevel: string;

    isAdmin(): boolean {
        return this.role && this.role == 'Account Admin';
    }
}


export interface IControllerDetails {
    id: string;
    accountName: string;
    legacyControllerID: number;
    number: number;
    controllerNumber: number;
    accountID: string;
    controllerTypeID: number;
    controllerModeID: number;
    model: string;
    name: string;
    location: string;
    latitude: number;
    longitude: number;
    controllerBillingTypeID: number;
    demoControllerEndDate: Date;
    displayStationSize: number;
    controllerStationSize: number;
    firmwareVersion: string;
    statusID: number;
    nextStatusID: number;
    dateActivation: Date;
    suspendStartDate: Date;
    suspendEndDate: Date;
    servicePlanID: number;
    rmeSerial: string;
    commSerial: string;
    pin: string;
    sim: string;
    cloudID: string;
    paNum: string;
    esn: string;
    controllerModelDetailID: number;
    permissions: ControllerPermissions;
    isPager: boolean;

    isEagle(): boolean;
    isEaglePlus(): boolean;
    isArchWireless(): boolean;
    getArchPin(): string;
    setArchPin(string);
    validModemPin(): boolean;
    isModem(): boolean;
    hasValidMode(): boolean;
    isActive(): boolean;
    isSuspended(): boolean;
    isDeleted(): boolean;
    isStatusPending(): boolean;
    isPendingSuspend(): boolean;
    isPendingActive(): boolean;
    isPendingDeleted(): boolean;
    isDemo(): boolean;
    servicePlanIsEthernet(): boolean;
    planAvailable(number): boolean;
    canManageController(): boolean;

}

export class ControllerDetails implements IControllerDetails {

    static readonly eagleArchPinSuffix = '@wctp.arch.com/wctp';

    id: string = DEFAULT_GUID;
    accountName = '';
    legacyControllerID = 0;
    accountID = '';
    number = 0;
    controllerNumber = 0;
    controllerTypeID = 0;
    controllerModeID = 0;
    model = '';
    name = '';
    location = '';
    latitude = 0;
    longitude = 0;
    controllerBillingTypeID = 0;
    demoControllerEndDate: Date = new Date();
    displayStationSize = 0;
    controllerStationSize = 0;
    firmwareVersion = '';
    statusID = 0;
    nextStatusID = 0;
    dateActivation: Date = new Date();
    suspendStartDate: Date = new Date();
    suspendEndDate: Date = new Date();
    servicePlanID = 0;
    rmeSerial = '';
    commSerial = '';
    pin = '';
    sim = '';
    cloudID = '';
    paNum = '';
    esn = '';
    controllerModelDetailID = 0;
    permissions: ControllerPermissions = 0;
    isPager: boolean;

    isEagle(): boolean {
        return this.controllerTypeID == 1;
    }
    isEaglePlus(): boolean {
        return this.controllerTypeID == 2;
    }
    isArchWireless(): boolean {
        return this.isEagle() && this.pin && this.pin.includes(ControllerDetails.eagleArchPinSuffix);
    }
    getArchPin(): string {
        if (!this.isArchWireless()) { return undefined; }
        const suffix = this.pin.indexOf(ControllerDetails.eagleArchPinSuffix);
        return this.pin.substring(0, suffix);
    }

    setArchPin(pin: string) {
        this.pin = pin + ControllerDetails.eagleArchPinSuffix;
    }

    validModemPin(): boolean {
        if (!this.pin) { return false; }
        if (this.isModem()) {
            return this.pin.length >= 7;
        } else {
            return this.pin.length > ControllerDetails.eagleArchPinSuffix.length;
        }
    }

    isModem(): boolean {
        return !this.isArchWireless();
    }
    hasValidMode(): boolean {
        if (!this.isEaglePlus()) { return true; }
        return this.controllerModeID && this.controllerModeID != 0;
    }
    isActive(): boolean {
        return this.statusID == 1;
    }
    isSuspended(): boolean {
        return this.statusID == 6;
    }
    isDeleted(): boolean {
        return this.statusID == 5;
    }

    isStatusPending(): boolean {
        return this.nextStatusID && this.statusID != this.nextStatusID;
    }

    isPendingSuspend(): boolean {
        return this.isStatusPending() && this.nextStatusID == 6;
    }

    isPendingActive(): boolean {
        return this.isStatusPending() && this.nextStatusID == 1;
    }

    isPendingDeleted(): boolean {
        return this.isStatusPending() && this.nextStatusID == 5;
    }

    isDemo(): boolean {
        return this.controllerBillingTypeID == 2;
    }
    servicePlanIsEthernet(): boolean {
        return this.servicePlanID == 3;
    }

    planAvailable(planId: number): boolean {
        switch (planId) {
            case 3:
                return !this.isEagle();
            default:
                return true;
        }
    }

    canManageController(): boolean {
        return FlagsHelper.hasFlag(this.permissions, ControllerPermissions.Manage);
    }

}

export interface IControllerModelDetail {
    id: number;
    controllerTypeID: number;
    controllerModeID: number;
    model: string;
    cabinet: string;
    maxPrograms: number;
    minStations: number;
    maxStations: number;
    masterValves: number;
    pumps: number;
    pumpStation: number;
    flowSensors: number;
    stationIncrement: number;
}


export interface IDashboardStatus {
    date: Date;
    count: number;
    link: string;
}

export interface ICriticalControllerStatus extends IDashboardStatus {
    controllerCount: number;
}

export interface IPaymentStatus extends IDashboardStatus {
    balance: number;
    daysPastDue: number;
}

export interface IDashboard {
    etMessages: ICriticalControllerStatus;
    rainShutdownMessages: IDashboardStatus;
    outOfSyncMessages: IDashboardStatus;
    adminMessages: IDashboardStatus;
    controllerAlarmsMessages: ICriticalControllerStatus;
    paymentMessages: IPaymentStatus;
}

export interface IUserPreferences {
    id: number;
    userID: string;
    defaultAccount: string;
    ownerOfAccount: string;
    screenWidth: number;
    screenHeight: number;
    browser: string;
    lastUpdated: Date;
}


export interface IDashboardAccount {
    accountID: string;
    organizationNumber: number;
    accountName: string;
    accountTerritoryID: number;
    accountStatusID: number;
    accountStatus: string;
    paymentLockout: boolean;
    dateCreation: Date;
    hierarchyLevel: number;
    balance: number;
    oldestDaysPastDue: number;
    controllerRatio: string;
    legacyOrganizationID: number;
    mainContact: IContact;
    billingContact: IContact;
}




export interface IDashboardController {
    id: string;
    accountName: string;
    organizationNumber: number;
    accountID: string;
    controllerType: string;
    controllerMode: string;
    name: string;
    location: string;
    latitude: number;
    longitude: number;
    controllerBillingType: string;
    statusID: number;
    status: string;
    rmeSerial: string;
    commSerial: string;
    pin: string;
    paNum: string;
    modemType: string;
    sim: string;
    legacyControllerID: number;
    firmwareVersion: string;
    dateActivation: Date;
    servicePlan: string;
    mainContact: IContact;
    billingContact: IContact;
}

export interface IDashboardFinances {
    totalAccountsWithCreditBalance: number;
    totalAccountsWithoutCreditBalance: number;
}

export enum ScheduledCommandFeature {
    fmi = 'FMI',
    autoLearn = 'AutoLearn'
}

export enum ScheduledCommandFrequency {
    daily = 'Daily',
    weekly = 'Weekly',
    monthly = 'Monthly',
}

export enum ScheduleType {
    dailyAtHourAndMinute = 'DailyAtHourAndMinute',
    atHourAndMinuteOnGivenDaysOfWeek = 'AtHourAndMinuteOnGivenDaysOfWeek',
    atHourAndMinuteOnGivenDaysOfMonth = 'AtHourAndMinuteOnGivenDaysOfMonth'
}

export interface ISchedule {
    type: ScheduleType;
    hour: number;
    minute: number;
}

export interface IScheduledCommandCreateRequest {
    accountId: string;
    name: string;
    scheduleFeature: ScheduledCommandFeature;
    startDate: Date;
    endDate: Date;
    enabled: boolean;
    schedule: ISchedule;
}

export interface IScheduledCommandUpdateRequest {
    name: string;
    groupID: string;
    accountID: string;
    startDate: Date;
    endDate: Date;
    enabled: boolean;
    schedule: ISchedule;
}

export interface IScheduledCommand {
    groupID: string;
    id: string;
    name: string;
    scheduledFeature: ScheduledCommandFeature;
    startDate: Date;
    endDate: Date;
    nextRunDate: Date;
    enabled: boolean;
    dateCreation: Date;
    schedule: string;
    userID: string;
    accountID: string;
}


export enum SUBMIT_COMMAND {
    TEST_COMMUNICATION = 'testcommunication',
    START_STATION = 'startstation',
    STOP_STATION = 'stopstation',
    AUTO_LEARN_FLOW_START = 'StartAutoLearnFlow',
    AUTO_LEARN_FLOW_STOP = 'StopAutoLearnFlow',
    START_PROGRAM = 'startprogram',
    STOP_PROGRAM = 'stopprogram',
    RAIN_SHUTDOWN = 'rainshutdown',
    RECYCLE_POWER = 'RecyclePower'
}

export enum COMMTEST_STATUS {
    STATUS_READY = 0,
    STATUS_ERROR = 1,
    STATUS_SUCCESS = 2,
    STATUS_INPROGRESS = 3,
    STATUS_SUBMITTED = 4,
    STATUS_TIMEOUT = 5
}

export enum COMMTEST_RESULT {
    TEST_RESULT_NOT_PUBLISHED = 'NotPublished',
    TEST_RESULT_PUBLISHED_FAILED = 'PublishedFailed',
    TEST_RESULT_IN_PROGRESS = 'InProgress',
    TEST_RESULT_PUBLISHED = 'Published',
    TEST_RESULT_DELIVERED = 'Delivered',
    TEST_RESULT_WAITINGFORRESPONSE = 'WaitingForResponse',
    TEST_RESULT_RESPONSERECEIVED = 'ResponseReceived',
    TEST_RESULT_ERROR = 'Error',
    TEST_RESULT_CANCELLED = 'Cancelled'
}

export enum CONNECTION_STATUS {
    STATUS_INITIATED = 0,
    STATUS_ERROR = 1,
    STATUS_SUCCESS = 2,
    STATUS_INPROGRESS = 3,
    STATUS_TIMEOUT = 4
}

export enum CONNECTION_TEST_ACTION {
    RESET = "Reset",
    CONNECTION_TEST_RUNNING = "Connection Test Running",
    RESTART_COMM_TEST = "Restart Comm Test",
    CONNECTION_TEST_ERROR = "Connection Test Error",
    CONNECTION_TEST_CANCEL = "Connection Test Cancel",
    COMM_TEST_CANCEL = "Comm Test Cancel"
}

export enum PERMISSION {
    ALL_TRUE = 1,
    ALL_FALSE = 2,
    SOME_TRUE_SOME_FALSE = 3
}

export enum CONTROLLER_TYPE {
    EAGLE = 'Eagle',
    EAGLE_PLUS = 'Eagle Plus'
}

export interface ICommunicationTest {
    accountID: string;
    organizationID: string;
    accountName: string;
    hierarchyLevel: number;
    parentAccountID: string;
    id: string;
    controller_ID: number;
    legacyControllerID: number;
    name: string;
    number: number;
    location: string;
    controllerType: string;
    controllerMode: string;
    model: string;
    displayStationSize: number;
    pin: string;
    latitude: number;
    longitude: number;
    controllerStatus: string;
    submitResult: COMMTEST_STATUS;
    testResult: COMMTEST_STATUS;
    submitID: string;
    haveAlarms: boolean;
    isOutOfSync: boolean;
    inRainShutdown: boolean;
    click: boolean;
    selected: boolean;
    disabled: boolean;
    permissions: Permissions;
    history: boolean;
    connection: CONNECTION_STATUS;
    sim: string;
    imsi: string;
    lastInSession: Date;
}

export interface ISubmitCommTest {
    controllerID: string;
    type: SUBMIT_COMMAND;
}

export interface ICommandInput {
    type: string;
    rainShutDownDays: number;
    controllerIDs: string[];
    accountId: string;
}

export interface ICommandOutput {
    timeQueued: string;
    id: string;
    controllerId: string;
    direction: string;
    timeSent: Date;
    transmissionCommandType: string;
    transmissionType: string;
    transmissionState: string;
    transmissionStatus: string;
}

export interface ICommandResults {
    id: string;
    status: string;
    createTime: Date;
    startTime: Date;
    command: ICommandInput;
    transmissions: ICommandOutput[];
}

export interface ICommTestResults {
    type: string;
    accountId: string;
    controllerId: string;
    createTime: Date;
    startTime: Date;
    completeTime: Date;
    id: string;
    status: COMMTEST_RESULT;
    user: string;
}

export interface IManualControllerStation {
    stationGPM: number;
    stationNumber: number;
    stationName: string;
    isValidStation: boolean;
    status: COMMTEST_STATUS;
    startSubmitId: string;
    stopSubmitId: string;
    click: boolean;
    counter: number;
    disabled: boolean;
}

export interface IManualControllerProgram {
    controllerID: string;
    legacyControllerID: number;
    legacyProgramID: number;
    programNumber: number;
    programName: string;
    status: COMMTEST_STATUS;
    startSubmitId: string;
    stopSubmitId: string;
    click: boolean;
    disabled: boolean;
}

export interface INetworkPerformance {
    networkConnection: boolean;
    networkStrength?: number;
    date: Date;
}

export class AerisDataSessionRes {
    controllerID: string;
    imsi: string;
    dataSession: DataSession;
}

export class DataSession {
    resultCode: number;
    dataSessionStatus: boolean;
}
export class AerisDeviceIdRes {
    controllerID: string;
    imsi: string;
    lastInSession: Date;

    constructor(controllerID: string, imsi: string, lastInSession: Date) {
        this.controllerID = controllerID;
        this.imsi = imsi;
        this.lastInSession = lastInSession;
    }
}

export class CheckDataSessionReq {
    controllerID: string;
    imsi: string;

    constructor(controllerID: string, imsi: string) {
        this.controllerID = controllerID;
        this.imsi = imsi;
    }
}


// Irrigation Schedule Report Starts
export class IsrProgramZone {
    name: string;
    number: number;
    enteredRuntime: string;
    adjustedRuntime: string;
    maxCycleTime?: string = '';
    soakTime?: string = '';

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }
}

export class IsrProgramTiming {
    startTime: string;
    endTime: string;
    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }
}

export class WateringType {
    type: number;
    week1?: number[] = [];
    week2?: number[] = [];
    skipDays?: number;
    daysLeft?: number;

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }
}

export class IsrProgramDetails {
    id: string; // controller id
    name: string;
    number: number; //programId
    timings: IsrProgramTiming[];
    usage: number[];
    zones: IsrProgramZone[];
    irrigationType: number;
    runtimeType: number;
    waterBudget?: number;
    watering: WateringType;

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }
}

export class IsrCalenderEvent {
    id: number;
    name: string;
    etAdjustment?: number = 0;
    startDate: Date;
    endDate: Date;
    programId: number;
    programNumber: number;
    allDay?: boolean = false;
    waterBudget?: number = 0;
    rainShutdown: boolean = false;
    eventColor?: string;

    get resourceId(): number {
        return this.programId;
    }

    set resourceId(value: number) {
        // required of object.assign.
    }

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }
}

export class Zone {
    number: number;
    name: string;
}
export class IsrProgram {
    id: number;
    name: string;
    zones: Zone[];
    number: number;
    eventColor?: string;

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }
}

// Irrigation Schedule Report Ends
export class CheckReRegistrationReq {
    controllerId: string;
    imsi: string;
    lastInSession: Date;

    constructor(controllerId: string, imsi: string, lastInSession: Date) {
        this.controllerId = controllerId;
        this.imsi = imsi;
        this.lastInSession = lastInSession;
    }
}

export class CheckReRegistrationRes {
    controllerId: string;
    imsi: string;
    reRegister: boolean;
    resultCode: number;

    constructor(controllerId: string, imsi: string, reRegister: boolean, resultCode: number) {
        this.controllerId = controllerId;
        this.imsi = imsi;
        this.reRegister = reRegister;
        this.resultCode = resultCode;
    }
}


import { CcControllerActionStatus } from '@app/common/services/models/cloud-connect/cc-controller-action-status.model';
import { CcRequestInfo } from '@app/common/services/models/cloud-connect/cc-request-info.model';

export class CloudConnectResponse {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.requestID) this.requestID = new CcRequestInfo(json.requestID);
			if (json.actionStatusByController) this.actionStatusByController = json.actionStatusByController.map(as => new CcControllerActionStatus(as));
		}
	}

	actionName: string;
	actionStatus: string;
	failReason: string;
	failCode: number;
	requestID: CcRequestInfo;
	connectionID: number;
	actionStatusByController: CcControllerActionStatus[];
}

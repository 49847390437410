import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ic-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {

  items: MenuItem[] = [];

  // =========================================================================================================================================================
  // C'tor and Lifecycle Hooks
  // =========================================================================================================================================================

  constructor(private translateService: TranslateService) {

  }

  ngOnInit() {
    this.buildMenubar();
  }

  private buildMenubar() {
    this.items = [
      {
        label: this.translateService.instant('MENUS.HOME'),
        icon: 'fa fa-home',
        routerLink: '/home'
      },
      {
        label: this.translateService.instant('MENUS.ACCOUNT'),
        icon: 'fa fa-cogs',
        items: [
          {
            label: this.translateService.instant('MENUS.EMAIL_SETUP'),
            items: [
              {
                label: this.translateService.instant('MENUS.NOTIFICATION_OPTIONS'),
                routerLink: '/user/notifications'
              },
              {
                label: this.translateService.instant('MENUS.EMAIL_ADDRESSES'),
                routerLink: '/user/preferences'
              }
            ]
          },
          {
            label: this.translateService.instant('MENUS.ACCOUNT_SELECT'),
            routerLink: '/account/account-select'
          },
          {
            label: this.translateService.instant('MENUS.CREATE_MAIN_ACCOUNT'),
            routerLink: '/account/create-account'
          }
        ]
      },
      {
        label: this.translateService.instant('MENUS.PROGRAM'),
        icon: 'fa fa-sliders',
        items: [
          {
            label: this.translateService.instant('MENUS.STANDARD_PROGRAMMING'),
            routerLink: '/program/conventional'
          },
          {
            label: this.translateService.instant('MENUS.AUTOMATIC_SCHEDULE_GENERATOR'),
            routerLink: '/program/asg'
          },
          {
            label: this.translateService.instant('MENUS.FLOW_MANAGED_IRRIGATION'),
            routerLink: '/program/flow-managed-irrigation'
          }
        ]
      },
      {
        label: this.translateService.instant('MENUS.MANUAL_OPS'),
        icon: 'fa fa-wrench',
        items: [
          {
            label: this.translateService.instant('MENUS.PROGRAMS_AND_STATIONS'),
            routerLink: '/manual-ops/program'
          },
          {
            label: this.translateService.instant('MENUS.AUTO_LEARN_FLOW'),
            routerLink: '/manual-ops/auto-learn'
          },
          {
            label: this.translateService.instant('MENUS.RETRIEVE_FLOW'),
            routerLink: '/manual-ops/retrieve-flow'
          },
          {
            label: this.translateService.instant('MENUS.COMMUNICATION_TEST'),
            routerLink: '/manual-ops/communication'
          },
          {
            label: this.translateService.instant('MENUS.CONTROLLER_CONNECTIVITY'),
            routerLink: '/manual-ops/controller-connectivity'
          }
        ]
      },
      {
        label: this.translateService.instant('MENUS.SHUTDOWN'),
        icon: 'fa fa-tint',
        routerLink: '/shutdown'
      },
      {
        label: this.translateService.instant('MENUS.WEATHER'),
        icon: 'fa fa-umbrella',
        items: [
          {
            label: this.translateService.instant('MENUS.SHARING_RAIN_WS_SOURCE'),
            routerLink: '/weather/sharing-rain'
          },
          {
            label: this.translateService.instant('MENUS.WEATHER_SETUP'),
            routerLink: '/weather/setup'
          }
        ]
      },
      {
        label: this.translateService.instant('MENUS.REPORTS'),
        icon: 'fa fa-pencil-square',
        routerLink: '/reports',
        items: [
          {
            label: this.translateService.instant('MENUS.COMMUNICATION_HISTORY'),
            items: [
              {
                label: this.translateService.instant('MENUS.ICENTRAL_TRANSACTIONS'),
                routerLink: '/report/icentral'
              },
              {
                label: this.translateService.instant('MENUS.CONTROLLER_TRANSMISSIONS'),
                routerLink: '/reports/controller'
              }
            ]
          },
          {
            label: this.translateService.instant('MENUS.IRRIGATION_SCHEDULE_REPORT'),
            routerLink: '/report/irrigation'
          },
          {
            label: this.translateService.instant('MENUS.WATER_USAGE_REPORT'),
            routerLink: '/reports/water-use'
          },
          {
            label: this.translateService.instant('MENUS.ALARM_REPORT'),
            routerLink: '/reports/alarms'
          },
          {
            label: this.translateService.instant('MENUS.DAILY_ET_REPORT'),
            routerLink: '/reports/et'
          }
        ]
      },
      {
        label: this.translateService.instant('MENUS.PAYMENTS'),
        icon: 'fa fa-credit-card',
        routerLink: '/billing',
        items: [
          {
            label: this.translateService.instant('MENUS.VIEW_PAY_BALANCE'),
            routerLink: '/billing/balance'
          },
          {
            label: this.translateService.instant('MENUS.UPCOMING_CHARGES'),
            routerLink: '/billing/upcoming'
          },
          {
            label: this.translateService.instant('MENUS.PAYMENT_HISTORY'),
            routerLink: '/billing/history',
          }
        ]
      },
      {
        label: this.translateService.instant('MENUS.CONTACT'),
        icon: 'fa fa-phone',
        items: [
          {
            icon: 'fa fa-headphones',
            label: this.translateService.instant('MENUS.PHONE'),
            url: 'tel:+18007771477'
          },
          {
            icon: 'fa fa-envelope-o',
            label: this.translateService.instant('MENUS.EMAIL'),
            url: 'mailto:icentral.support@rainmaster.com'
          }
        ]
      },
      {
        label: this.translateService.instant('MENUS.HELP'),
        icon: 'fa fa-question-circle',
        url: 'https://www.irrigationdocuments.com/icentral/en-us/help/index.html',
      }
    ];
  }
}
